import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initA11yFunctions from '/lib/accessibility'
import initDropdownButton from '/lib/dropdown-button'
import initBlogNewsSliders from '/lib/blog-news'
import initTooltips from '/lib/tooltip'
import initBlogPostImages from '/lib/blogPostImages'
import initWavyImages from '/lib/wavy-images'
import initAdjustScrolling from '/lib/scroll'
import initPdfLinks from '/lib/pdfLinks'

initGreenSock()
initAdjustScrolling()
initHeadroom()
initMobileNav()
initA11yFunctions()
initDropdownButton()
initBlogNewsSliders()
initTooltips()
initBlogPostImages()
initWavyImages()
initPdfLinks()