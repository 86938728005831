import $ from 'jquery'
const $doc = $(document)

function openCustomDropdown(event) {
    event.stopPropagation(); // Prevent the click from propagating to the document
    console.log('openCustomDropdown');
    var dropdownList = $(this).parent().find('.dropdown-button__list');
    $('.dropdown-button__list').not(dropdownList).hide(); // Hide other open dropdowns
    $(this).parent().find('.dropdown-button__list').toggle();
}

function closeAllDropdowns() {
    $('.dropdown-button__list').hide();
}

function isDesktop() {
    return window.innerWidth >= 960; // You can adjust the width threshold as needed
}

export default function initDropdownButton() {
    // Initialize dropdowns
    function initDropdowns() {
        if (isDesktop()) {
            // Attach hover event to the dropdown button
            $('.footer__upper__right__dropdowns .dropdown-button__link').on('mouseenter', openCustomDropdown);

            // Attach leave event to hide the dropdown when the cursor is not on the link or the list
            $('.footer__upper__right__dropdowns .dropdown-button').on('mouseleave', function () {
                $(this).find('.dropdown-button__list').hide();
            });
        } else {
            // Remove hover events for mobile
            $('.footer__upper__right__dropdowns .dropdown-button__link').off('mouseenter', openCustomDropdown);
            $('.footer__upper__right__dropdowns .dropdown-button').off('mouseleave');
        }
    }

    $doc.on('click', '.dropdown-button__link', openCustomDropdown);
    $doc.on('click', '.dropdown-button ul li a', closeAllDropdowns);

    // Add event listener to the document for closing dropdowns on outside click
    $doc.on('click', function (event) {
        if (!$(event.target).closest('.dropdown-button').length) {
            closeAllDropdowns();
        }
    });

    // Initialize dropdowns on page load
    initDropdowns();

    // Re-initialize dropdowns on window resize
    $(window).on('resize', initDropdowns);
}
