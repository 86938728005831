import $ from 'jquery'

function wavyImagesSectionMarginAdjustment(event) {
    var firstSection = $('section').first();
    if (firstSection.find('.mg-block--wavy-border-bottom').length > 0) {
        // Get the second <section> below the first <section>
        var secondSection = firstSection.next('section');

        // If the second section exists
        if (secondSection.length > 0) {
            // Get current top padding
            var currentPaddingTop = parseInt(secondSection.css('padding-top'), 10);

            // Add additional 100px to the top padding
            secondSection.css('padding-top', (currentPaddingTop + 100) + 'px');

            // Add margin of -100px to the second section
            secondSection.css('margin-top', '-100px');
        }
    }
}

export default function initWavyImages() {
    wavyImagesSectionMarginAdjustment()
}
