export default function initPdfLinks() {
    document.addEventListener("DOMContentLoaded", function () {
        // Detect if the user is on an Android device
        const isAndroid = navigator.userAgent.toLowerCase().includes("android");

        // Get all <a> tags on the page
        const links = document.querySelectorAll("a");

        // Loop through each <a> tag
        links.forEach(link => {
            // Check if the href attribute ends with .pdf
            if (link.href.toLowerCase().endsWith(".pdf")) {
                // Set the target attribute to _blank to open in a new tab
                link.setAttribute("target", "_blank");

                // Ensure noopener and noreferrer for security
                link.setAttribute("rel", "noopener noreferrer");

                // If on Android, add the download attribute
                if (isAndroid) {
                    link.setAttribute("download", link.href.split('/').pop());

                    // Optionally, you can remove the target="_blank" for Android
                    // to force the download directly without opening a new tab.
                    link.removeAttribute("target");
                }

                // Add a click event listener as a fallback for browsers that might block window.open
                link.addEventListener('click', function (event) {
                    // If on Android, do not open a new tab, just download
                    if (isAndroid) {
                        return; // Let the download attribute handle it
                    }

                    event.preventDefault();

                    const newTab = window.open(link.href, '_blank', 'noopener,noreferrer');

                    // Fallback to using an iframe in case the new tab is blocked
                    if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
                        const iframe = document.createElement('iframe');
                        iframe.style.display = 'none';
                        iframe.src = link.href;
                        document.body.appendChild(iframe);
                    }
                });
            }
        });
    });
}
