import $ from 'jquery'

function adjustScroll() {
    const hash = window.location.hash;
    if (hash) {
        const target = document.querySelector(hash);
        if (target) {
            window.scrollTo({
                top: target.offsetTop + 100, // Adjust the scroll position by 100px
                behavior: 'smooth'
            });
        }
    }
}

export default function initAdjustScrolling() {
    window.addEventListener('hashchange', adjustScroll);
    window.addEventListener('load', adjustScroll);
}
