import $ from 'jquery'

function initBlogPostImages(event) {
    const images = document.querySelectorAll('.article.post .wysiwyg-editor img');
    const colors = ['#66BECB', '#FFBE1F', '#3676BC'];

    images.forEach((image, index) => {
        const colorIndex = index % colors.length;
        image.style.border = `15px solid ${colors[colorIndex]}`;
    });
}

export default function blogPostImages() {
    initBlogPostImages()
}
