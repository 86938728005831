import $ from 'jquery';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
var tooltipContents = {};

function setupTooltipKeywords(event) {
    var tooltipIds = [];
    // Collect all data-tooltip-id values
    $('[data-tooltip-id]').each(function () {
        tooltipIds.push($(this).attr('data-tooltip-id'));
    });

    // Function to find and wrap the first occurrence of any of the tooltipIds
    function wrapFirstOccurrence() {
        // Recursive function to traverse text nodes
        function traverse(node, pattern, word) {
            if (node.nodeType === 3) { // Node.TEXT_NODE
                var match = node.data.match(pattern);
                if (match) {
                    var id = word.replace(/\s+/g, '-'); // Replace spaces with dashes
                    var span = $('<span class="tooltip__word" id="' + id + '"></span>').attr('btn-string', id).text(match[0]);
                    var newText = node.data.replace(pattern, span[0].outerHTML);
                    $(node).replaceWith(newText);
                    return true; // Found and replaced, stop further searching
                }
            } else if (node.nodeType === 1 && node.nodeName !== 'SCRIPT' && node.nodeName !== 'STYLE') { // Node.ELEMENT_NODE
                // Skip elements with class 'tooltips' or parent with class 'accordion__inner__single-item-content'
                if ($(node).hasClass('tooltips') || $(node).closest('.accordion__inner__single-item-content').length > 0) {
                    return false;
                }

                for (var i = 0; i < node.childNodes.length; i++) {
                    if (traverse(node.childNodes[i], pattern, word)) {
                        return true; // Found and replaced, stop further searching
                    }
                }
            }
            return false; // Continue searching
        }

        // Iterate over all tooltipIds and find the first occurrence for each
        for (var i = 0; i < tooltipIds.length; i++) {
            var word = tooltipIds[i];
            var pattern = new RegExp('(' + word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ')'); // Escape special characters
            traverse(document.body, pattern, word);
        }
    }

    // Execute the function to wrap the first occurrence
    wrapFirstOccurrence();
}

function fetchTooltipContents() {
    // Collect HTML of .tooltip__content for each element with data-tooltip-id
    $('[data-tooltip-id]').each(function () {
        var tooltipId = $(this).attr('data-tooltip-id');
        var tooltipContent = $(this).find('.tooltip__content').html();
        tooltipContents[tooltipId] = tooltipContent;
    });
    return tooltipContents;
}

function initKeywordTooltips() {
    for (var key in tooltipContents) {
        if (tooltipContents.hasOwnProperty(key)) {
            var selector = 'span.tooltip__word#' + key.replace(/\s+/g, '-'); // Replace spaces with dashes in the selector
            tippy(selector, {
                content: tooltipContents[key],
                allowHTML: true,
                role: 'tooltip',
                arrow: true,
                delay: 0,
                inertia: true,
                interactive: true,
                interactiveBorder: 5,
                maxWidth: 300,
                aria: {
                    content: 'auto',
                    expanded: 'auto',
                },
                aria: {
                    content: 'labelledby',
                },
                aria: {
                    content: 'describedby',
                },
                aria: {
                    content: null,
                    expanded: false,
                },
            });
        }
    }
}

export default function initTooltips() {
    setupTooltipKeywords();
    fetchTooltipContents();
    initKeywordTooltips();
}
